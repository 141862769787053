@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';

.zestur-candidate-wrapper {
  background-color: $white-smoke;
  margin-top: $header-height;
  padding: 1.2em;

  @include mobile-breakpoint {
    background-color: rgba(122, 175, 187, 0.05);
    margin-top: $header-height-mobile;
    padding: 0.5em;
  }
}
