@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';

.vacancy {
  cursor: pointer;
  background-color: white;
  border-radius: 12px;

  gap: 18px;
  padding: 24px 24px 24px 22px;

  &__title {
    > :first-child {
      font-size: 18px;
      font-weight: $font-bold;
      line-height: 32px;
    }

    > :last-child {
      font-size: 14px;
    }
  }

  &__logo {
    min-width: 64px;
    width: 64px;
    height: 64px;

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &__details {
    font-size: 14px;
    line-height: 24px;
  }

  &__description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__match {
    justify-content: space-between;
    margin-left: 30px;
    margin-top: 12px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.5rem;

    @include md-desktop-breakpoint {
      row-gap: 0.5rem;
      column-gap: 1rem;
    }
  }

  @include mobile-breakpoint {
    padding: 24px;

    &__title {
      > :first-child {
        font-size: 1rem;
        line-height: normal;
      }
    }

    &__match {
      display: flex;
      justify-content: flex-end;

      > :first-child {
        width: 212px;
        margin-top: 0;
        margin-left: auto;
      }

      &.justify-content-end {
        > :first-child {
          width: initial;
        }
      }
    }

    &__description {
      font-size: 14px;
    }

    &__info {
      display: grid;
      grid-template-columns: repeat(2, 2fr);

      span {
        line-height: 15px;
      }

      > :last-child {
        margin-left: 2px;
      }
    }
  }
}
