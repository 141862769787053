.tab-list {
  list-style: none;
  padding: 0;
  display: flex;
}

.tab-item {
  padding: 10px 20px;
  border: 1px solid #ccc;
}

.tab-item.active {
  background-color: #f0f0f0;
  font-weight: bold;
}

.tab-content {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-align: left;
}

th {
  background: #CCC;
}