@import '../base/var';
@import '../../assets/scss/breakpoint/breakpoints-mixins';

.app-table {
  width: 100%;
  border-collapse: separate;
  thead {
    tr {
      th {
        font-size: 14px;
        color: $dark-blue;
        font-weight: bold;
        padding: 1em 0.7em;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 14px;
        padding: 1em 0.7em;
        background-color: $white-smoke;
        .table-link {
          text-decoration: underline;
          color: $dark-blue;
          cursor: pointer;
        }
      }
    }
  }
}
.action-row {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 48%;
  transform: translate(-50%);
  margin: 24px;
  width: 90%;
  box-sizing: border-box;
  height: 60px;
  background-color: $light-blue;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 2em;
  margin-top: 1em;

  &-actions {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    button {
      background: transparent !important;
      border: none;
      color: white;
      display: flex;
      align-items: center;
      img {
        margin-right: 1em;
      }
    }
  }

  @include tablet-breakpoint {
    &-actions {
      gap: 6px;
    }
  }

  @include mobile-breakpoint {
    height: fit-content;
    left: 45%;
    &-actions {
      flex-direction: column;
    }
  }
}

.table-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  .spinner {
    position: absolute;
    width: 9px;
    height: 9px;
  }

  .spinner div {
    position: absolute;
    width: 50%;
    height: 150%;
    background: #000000;
    transform: rotate(calc(var(--rotation) * 1deg))
      translate(0, calc(var(--translation) * 1%));
    animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
  }

  .spinner div:nth-child(1) {
    --delay: 0.1;
    --rotation: 36;
    --translation: 150;
  }

  .spinner div:nth-child(2) {
    --delay: 0.2;
    --rotation: 72;
    --translation: 150;
  }

  .spinner div:nth-child(3) {
    --delay: 0.3;
    --rotation: 108;
    --translation: 150;
  }

  .spinner div:nth-child(4) {
    --delay: 0.4;
    --rotation: 144;
    --translation: 150;
  }

  .spinner div:nth-child(5) {
    --delay: 0.5;
    --rotation: 180;
    --translation: 150;
  }

  .spinner div:nth-child(6) {
    --delay: 0.6;
    --rotation: 216;
    --translation: 150;
  }

  .spinner div:nth-child(7) {
    --delay: 0.7;
    --rotation: 252;
    --translation: 150;
  }

  .spinner div:nth-child(8) {
    --delay: 0.8;
    --rotation: 288;
    --translation: 150;
  }

  .spinner div:nth-child(9) {
    --delay: 0.9;
    --rotation: 324;
    --translation: 150;
  }

  .spinner div:nth-child(10) {
    --delay: 1;
    --rotation: 360;
    --translation: 150;
  }

  @keyframes spinner-fzua35 {
    0%,
    10%,
    20%,
    30%,
    50%,
    60%,
    70%,
    80%,
    90%,
    100% {
      transform: rotate(calc(var(--rotation) * 1deg))
        translate(0, calc(var(--translation) * 1%));
    }

    50% {
      transform: rotate(calc(var(--rotation) * 1deg))
        translate(0, calc(var(--translation) * 1.5%));
    }
  }
}
