.triggerButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;

  img {
    fill-opacity: 0;
  }
}
