@import '../../assets/scss/variable';
@import '../../assets/scss/breakpoint/breakpoints-mixins';

.location {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  border-radius: 12px;
  color: $main-black;

  &__info {
    padding: 26px;
  }

  @include mobile-breakpoint {
    gap: 0;
  }

  .preview {
    background-color: white;
    border: 1px solid #eaeaea;
    border-radius: 12px;
    padding-bottom: 42px;

    &__image {
      height: 180px;
      border-radius: 10px;
      object-fit: cover;
    }

    &__logo {
      width: 150px;
      height: 150px;
    }

    &__logo-container {
      background-color: white;
      left: 2rem;
      bottom: -1.7rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &__title {
      margin-left: 226px;
      font-size: 18px;
      font-weight: $font-bold;

      > :first-child {
        font-size: 20px;
        line-height: 2rem;
      }

      > :last-child {
        color: $dark-grey;
        font-family: 'Oswald';
        font-size: 14px;
      }
    }

    &__general {
      margin-top: 30px;
    }

    @include mobile-breakpoint {
      border: none;
      &__image {
        height: 98px;
      }

      &__logo {
        height: 100px;
        width: 100px;
        border: 5px solid $gray-border;
      }

      &__logo-container {
        box-shadow: none;
        left: 10px;
        bottom: -10px;
      }

      &__title {
        margin-left: 120px;

        > :first-child {
          font-size: 18px;
          line-height: normal;
        }

        & > :nth-child(even) {
          font-size: 13px;
        }
      }

      &__general {
        margin-top: 12px;
      }
    }
  }
}
