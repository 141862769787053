.mobile-progress {
  width: 100%;
  z-index: 12;
  align-items: end;
  gap: 1rem;

  .progress {
    width: 100%;
    height: 16px!important;
  }
}
