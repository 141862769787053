.nav-tabs {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1em;
  display: flex;
  .nav-item .nav-link {
    text-decoration: none;

    &.active {
      color: $light-blue !important;
      border-bottom-color: $light-blue !important;
    }
    color: $graylight !important;
    border: none;
    border-bottom: 2px solid $graylight;
  }
}

.tab-heading {
  font-size: 20px;
}
