// Colors
$main-black: #333333;
$dark-blue: #213867;
$dark-blue-alt: #264176;
$light-blue: #0c85d0;
$dark-grey-alt: #5b5b5c;
$dark-grey: #7c7c7c;
$light-red: #ff6a55;
$graylight: #a7a7a7;
$gray-border: #efefef;
$grey: #bebebe;
$grey-neutral: #dddddd;

$white-smoke: #f3f3f3;

$black-900: #252525;
// All Fonts weight Variables
$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-black: 900;

// Header
$header-height: 88px;
$header-height-mobile: 4rem;
