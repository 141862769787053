.tag {
  max-height: 40px;
  gap: 8px;
  padding: 9px 16px;
  border-radius: 20px;
  background-color: rgba(233, 233, 233, 0.60);
  font-size: 14px;

  &-icon {
    height: 20px;
    width: 20px;
  }

  &-close {
    height: 16px;
    width: 16px;
  }
}

.bigger-icon {
  width: 24px;
  height: 24px;
}