@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';

.filter {
  &__button {
    display: inline-flex;
    align-items: center;

    color: $dark-blue;
    gap: 4px;
    height: 50px;
    padding: 16px 24px 16px 16px;

    border-radius: 12px;
    border: 2px solid $dark-blue;
  }

  @include mobile-breakpoint {
    &__button {
      background-color: transparent;
      border: none;
    }
  }
}

