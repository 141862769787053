@import '../../assets/scss/variable';
@import '../../assets/scss/breakpoint/breakpoints-mixins';

.match-modal {
  &__info {
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    gap: 6px;
  }

  &__description {
    border: 2px solid $graylight;
    border-radius: 12px;
    padding: 1rem;

    @include mobile-breakpoint {
      p {
        font-size: 14px;
        word-break: break-all;
      }
    }
  }
}
