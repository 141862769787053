@import '../../../assets/scss/variable';

.download {
  font-size: 16px;
  font-weight: $font-semi-bold;
  line-height: 16px;
  color: $dark-blue;
  background-color: transparent;
  border: none;

  img {
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
}