@import '../../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../../assets/scss/variable';

.candidate-profile-section {
  width: 100%;
  position: relative;
  margin-bottom: 6em;
  height: 200px;
  &-holder {
    width: 100%;
    overflow: hidden;
    height: 200px;
    img {
      width: 100%;
      height: 200px !important;
      border-radius: 10px;
      object-fit: cover;
    }
    .btn-edit-profile {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      z-index: 2;
      border-radius: 2em;
      background-color: rgba(122, 175, 187, 0.8);
      opacity: 0.8;
      padding-left: 2em;
      padding-right: 2em;
      color: white;
    }
  }
  .candidate-bio {
    padding: 1em 0;
    h2 {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 0.2em;
    }
  }
  .profile-picture {
    border-radius: 50%;
    position: absolute;
    bottom: -3em;
    left: 2em;
    img {
      cursor: pointer;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  @include mobile-breakpoint {
    .profile-picture {
      left: 4px;

      img {
        width: 114px;
        height: 114px;
        box-shadow: none;
      }
    }
  }
}

.w-200px {
  width: 250px !important;
}

.contact-title {
  font-size: 26px;
  font-weight: bold;
}

.map-area {
  background-color: $white-smoke;
  border-radius: 10px;
  height: 200px;
  margin: 2em 1em;
  box-sizing: border-box;
}
