@import '../../../assets/scss/breakpoint/breakpoints-mixins';

.language-form {
  @include mobile-breakpoint {
    .language {
      display: flex;
      flex-direction: column;
    }
  }
}
