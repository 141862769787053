@import '../../assets/scss/variable';

.vacancyCardContainer {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.vacancyCard {
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid $dark-blue;

  h6 {
    height: 40px;
    font-weight: $font-semi-bold;
  }
  p {
    color: $graylight;
  }
}

.vacancyCardActive {
  background-color: $white-smoke !important;
}
