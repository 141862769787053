.back-arrow {
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 12;

  h4 {
    font-size: 24px !important;
  }

  button {
    background-color: transparent;
    border: none;

    &>:first-child {
      margin-right: 4px;
    }
  }
}