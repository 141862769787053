@import '../../../assets/scss/variable';

.match-tag {
  font-family: 'Open Sans';
  width: fit-content;
  white-space: nowrap;
  border: none;
  text-align: center;
  color: white;
  font-size: 14px;
  max-height: 28px;
  padding: 4px 12px;

  border-radius: 24px;
  opacity: 0.8;
  background-color: $dark-blue;
}
