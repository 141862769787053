@import '../../../assets/scss/variable';

.delete-button {
  color: $dark-blue;
  font-weight: $font-semi-bold;
  font-size: 16px;
  background-color: transparent;
  border: none;

  &.red {
    color: $light-red;
  }
}