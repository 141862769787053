@import '../../../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../../../assets/scss/variable';

.vacancies {
  &__title {
    margin: 2rem 0;

    h1 {
      font-size: 26px;
      font-weight: $font-bold;
    }
  }

  &__sort {
    display: flex;
    font-size: 14px;

    >:first-child {
      color: $dark-grey;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  @include mobile-breakpoint {
    &__title {
      h1 {
        font-size: 20px;
      }
    }
  }
}