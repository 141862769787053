@import './../../../assets/scss/variable';

.action-button {
  background-color: transparent;
  border: none;
}

.action-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 2px solid $dark-blue;
  border-radius: 1rem;
  background-color: white;
  width: 180px;
  top: 40px;
  left: -160px;
  z-index: 1;

  &>:first-child {
    border-bottom: 1px solid $dark-blue;
  }

  button {
    padding: 8px 15px;
    text-align: start;
  }

  img {
    margin-right: 6px;
  }
}
