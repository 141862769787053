@import '../../../assets/scss/breakpoint/breakpoints-mixins';

.actual-checkbox {
  position: absolute;
  bottom: -2rem;
  left: 0.2rem;
  display: flex;
  gap: 0.5rem;

  @include mobile-breakpoint {
    bottom: -1.5rem;
    right: 0;
    left: initial;
  }
}