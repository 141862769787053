@import '../../../assets/scss/variable';
@import '../../../assets/scss/breakpoint/breakpoints-mixins';

.download-button {
  right: 1rem;
  top: 1.5rem;
  position: absolute;
  display: flex;
  gap: 6px;

  @include mobile-breakpoint {
    position: initial;
    display: flex;
    justify-content: center;
  }
}

.zestur-card {
  @include mobile-breakpoint {
    h5 {
      margin-bottom: 12px !important;
    }
  }
}

.mobile-add-button {
  margin-left: auto;
  img {
    width: 32px;
    height: 32px;
  }

  p {
    font-family: 'Oswald';
    font-size: 12px;
  }
}

.candidate-profile {
  text-align: center;
  margin: 1em 0;
  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    @include mobile-breakpoint {
      width: 83px;
      height: 83px;
    }
  }

  h2 {
    color: #252525;
    margin-top: 1em !important;
    font-weight: $font-bold;
    letter-spacing: -2px;
    font-size: 30px;
  }
  p {
    color: $graylight;
    font-weight: $font-semi-bold;
    font-size: 20px;
    margin: 0.8em 0;
  }

  @include mobile-breakpoint {
    h2 {
      font-family: 'open sans';
      color: $dark-blue-alt;
      font-size: 20px;
      margin-bottom: 6px !important;
    }
  }
}

.detail-expand {
  @include mobile-breakpoint {
    display: flex;
    align-items: flex-end;

    > :first-child {
      border: none;
      background-color: transparent;
      color: $dark-blue;
      font-size: 14px;
      font-weight: $font-semi-bold;
      line-height: 20px;
      margin-left: auto;

      border-bottom: 1px solid $dark-blue;
      padding-bottom: 2px;
    }
  }
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.info-section {
  border-bottom: 1px solid $white-smoke;
  &-title {
    font-weight: $font-bold;
  }
  &-sub-title {
    font-size: 16px;
    color: $graylight !important;
  }
  &-bullets-title {
    font-weight: $font-semi-bold;
  }
  &-date {
    &.data-gray {
      color: $graylight;
    }
  }
}

.actions {
  display: flex;
  gap: 1.5rem;
}
