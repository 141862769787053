@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/mixins';
@import '../../assets/scss/variable';

.details {
  background-color: white;
  border-radius: 12px;

  &__image {
    height: 180px;
    border-radius: 10px;
    object-fit: cover;
  }

  &__logo {
    width: 150px;
    height: 150px;

    &.without-match {
      bottom: -1rem;
    }
  }

  &__logo-container {
    left: 2rem;
    bottom: 0.25rem;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  &__title {
    margin-left: 210px;
    font-size: 18px;

    > :first-child {
      font-weight: $font-bold;
      line-height: 2rem;
    }

    > :last-child {
      display: flex;
      align-items: center;
    }
  }

  &__location {
    color: $dark-grey;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &::before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background-color: $grey;
    }
  }

  &__general {
    margin-top: 12px;
  }

  &__actions {
    display: flex;
    gap: 1.5rem;

    margin-right: 2rem;
  }

  &__applicant {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 287px;
    margin-right: 0.5rem;
    background-color: $dark-blue;
    color: white;
    border: none;
    border-radius: 0.5rem;
    height: 50px;
    padding: 16px;

    &-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 287px;
      color: $dark-blue;
      font-size: 18px;
      font-weight: $font-semi-bold;
      height: 50px;
    }
    @include md-desktop-breakpoint {
      width: 100px;
      margin-right: 0;

      &-empty {
        width: 150px;
        margin-right: 0;
      }
    }

    @include mobile-breakpoint {
      &-empty {
        width: 100%;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;
  }

  &__share {
    @include grey-icon-button;
  }

  &__salary {
    margin-bottom: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem;
  }

  &__info-container {
    h4,
    h5 {
      font-size: 24px;
      color: $dark-blue;
    }

    h4 {
      font-weight: $font-medium;
    }

    > :first-child {
      margin-bottom: 1.5rem !important;
      font-weight: $font-semi-bold;
    }

    > :last-child {
      gap: 3rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h5 {
      font-weight: $font-normal;
    }

    &-language {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    > :last-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__list {
    font-size: 1rem;
    font-weight: $font-semi-bold;

    ul {
      color: #5a5a5b;
    }
  }

  &__actions-mobile {
    display: none;
  }

  @include md-desktop-breakpoint {
    &__salary {
      flex-wrap: wrap;
      row-gap: 14px;
    }

    &__info {
      &-language {
        display: grid;
        row-gap: 14px;
        grid-template-columns: repeat(1, 1fr);
      }

      > :last-child {
        display: grid;
        row-gap: 14px;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  @include tablet-breakpoint {
    &__actions-mobile {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__buttons {
      flex-direction: column;
      align-items: end;
      gap: 6.5rem;

      > :first-child {
        display: flex;
        gap: 12px;

        button {
          background-color: white;
        }
      }

      > :last-child {
        margin-right: 2px;
        opacity: 1;
      }
    }
  }

  @include mobile-breakpoint {
    &__image {
      height: 98px;
    }

    &__logo {
      height: 100px;
      width: 100px;
      border: 5px solid $gray-border;
    }

    &__logo-container {
      box-shadow: none;
      left: 0;
      bottom: -10px;
    }

    &__title {
      margin-left: 120px;

      > :first-child {
        font-size: 18px;
        line-height: normal;
      }

      & > :nth-child(even) {
        font-size: 13px;
        text-decoration: underline;
      }
    }

    &__content {
      gap: 14px;
      padding: 16px;
      font-size: 14px;
    }

    &__general {
      flex-direction: column;
      row-gap: 10px;
      padding: 0 10px;
    }

    &__applicant {
      width: 100%;
    }

    &__actions {
      margin-right: 0;
      margin-top: 10px;
    }

    &__salary {
      margin-bottom: 0.6rem;
    }

    &__info-container {
      > :last-child {
        gap: 1.5rem;
      }
    }

    &__info {
      gap: 0.9rem;
    }

    &__actions-mobile {
      right: -2px;
      top: 0;
    }

    &__buttons {
      gap: 1.4rem;
    }
  }
}
