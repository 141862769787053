@import '../../assets/scss/variable';

.sort {
  &__button, &__button-mobile {
    display: flex;
    align-items: center;
    border: none;
    gap: 4px;
  }

  &__button-mobile {
    background-color: transparent;

    >:last-child {
      color: $dark-blue;
    }
  }

  >:first-child {
    font-size: 14px;
  }

  .caret-up {
    transform: rotate(180deg);
  }
}
