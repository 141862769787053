.search {
  &__input {
    position: relative;
  }

  &__button {
    position: absolute;
    right: 0.2em;
    top: 50%;
    transform: translateY(-50%);
  }
}
