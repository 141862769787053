@import '../../../assets/scss/variable';

.progressBarTitle {
  h4 {
    font-size: 22px;
    font-weight: $font-bold;
  }
  h5 {
    font-size: 18px;
    font-weight: $font-bold;
    color: $graylight;
  }
}
