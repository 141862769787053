.cb-candidate-list {
  padding: 1em 1.5em;
  height: calc(100vh - 130px);
  box-sizing: border-box;
  border-right: 1px solid $white-smoke;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: lighten($grey-neutral, 10%);
  }
  ::-webkit-scrollbar-thumb {
    background: $grey-neutral;
    border-radius: 1em;
  }

  &-crr-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    border-bottom: 1px solid $white-smoke;
    span {
      color: $dark-blue;
      font-size: 20px;
      font-weight: $font-semi-bold;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  &-search {
    .users-search {
      position: relative;
      button {
        right: 0.5em;
      }
      .input {
        border: 1px solid $graylight !important;
        border-color: $graylight !important;
        padding: 12px 20px 12px 12px !important;
      }
    }
    ::placeholder {
      color: $graylight !important;
    }
  }
  &-header {
    label {
      color: #69717c;
      font-size: 14px;
    }
  }
  &-body {
    overflow-y: auto;
    max-height: calc(100vh - 400px);
    .candidate {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 1em;
      }
      padding: 0.5em 1em;
      border-radius: 0.8em;
      margin: 0.3em 0;
      &.active {
        background-color: #7aafbb;
        color: white;
      }
      span {
        font-size: 15px;
      }
    }
  }
}

.chat-main {
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: lighten($grey-neutral, 10%);
  }
  ::-webkit-scrollbar-thumb {
    background: $grey-neutral;
    border-radius: 1em;
  }
  .chat-container {
    display: flex;
    flex-direction: column;
    height: 60vh;
    overflow-y: auto; /* Enable vertical scrolling if content exceeds the height */
  }

  .messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
  }

  .message-container {
    display: flex;
    flex-direction: column;
  }

  .message {
    margin-bottom: 2em;
    padding: 8px 1em;
    border-radius: 8px;
    font-size: 15px;
    animation: msg 0.3s ease-in-out forwards;
  }
  @keyframes msg {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .user-message {
    align-self: flex-end;
    background-color: $light-blue;
    color: white;
    margin-right: 2em;
    position: relative;
    &::before {
      content: '';
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 21px solid $light-blue;
      transform: rotate(180deg);
      border-bottom-left-radius: 7px;
      border-top-left-radius: 40px;
      position: absolute;
      right: -5px;
      top: 0px;
      z-index: 10;
    }
  }

  .receiver-message {
    align-self: flex-start;
    background-color: #f1f2f6;
    color: #444b61;
    margin-left: 2em;
    position: relative;
    &::before {
      content: '';
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 21px solid #f1f2f6;
      transform: rotate(180deg);
      border-bottom-right-radius: 7px;
      border-top-right-radius: 40px;
      position: absolute;
      left: -5px;
      top: 0px;
      z-index: 10;
    }
  }

  // sender
  .sender-info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .sender-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .sender-name {
    font-weight: bold;
    display: flex;
    align-items: center;
    .time {
      color: #69717c !important;
      margin-left: 1em;
      font-size: 12px;
    }
  }

  // receiver
  .receiver-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 4px;
  }

  .receiver-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 8px;
  }

  .receiver-name {
    font-weight: bold;
    display: flex;
    align-items: center;
    .time {
      color: #69717c !important;
      margin-right: 1em;
      font-size: 12px;
    }
  }

  // ---------------
  .input-container {
    display: flex;
    padding: 10px;
    border: 1px solid $graylight;
    border-radius: 10px;
    flex-direction: column;
    input {
      flex-grow: 1;
      padding: 8px;
      margin-right: 8px;
      border: none;
      outline: none;
    }
  }
}

.cb-user-crr {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid $white-smoke;
  img {
    width: 40px;
    height: 40px;
    margin-right: 1em;
    border-radius: 50%;
  }
}
