@import '../../../../assets/scss/variable';
@import '../../../../assets/scss/breakpoint/breakpoints-mixins';

.offer-form-header {
  font-size: 24px;
  color: $dark-blue;
  font-weight: $font-normal;
}

.offer-modal-row {
  margin: 0 48px !important;

  @include mobile-breakpoint {
    margin: 0 1rem !important;
  }

  p {
    white-space: pre-line;
  }
}