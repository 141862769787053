@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixins';

.zestur-sidebar {
  &__links-item {
    padding: 28px 30px;
    .item-text {
      font-size: 18px;
      margin-left: 40px;
    }

    &:not(.active) {
      .icon-container {
        @include ligth-grey-filter;
      }
    }

    &:hover:not(.active) {
      color: $dark-blue;
      .icon-container {
        @include dark-blue-filter;
      }
    }
  }

  overflow-y: auto;
}
