@import '../../../assets/scss/variable';

.zestur-card {
  .logo {
    width: 61px;
    height: 61px;
    border-radius: 10px;
    object-fit: cover;
  }

  .list-item-wrapper {
    &:first-child {
      margin-top: 2rem;
    }
    border-radius: 10px;
    border: 1px solid $graylight;
    padding: 10px 12px;

    button {
      white-space: nowrap;
    }
  }
}

.request-table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
  margin-top: 24px;

  thead {
    margin-bottom: 62px;
    th {
      color: $main-black;
      border: none !important;
      font-size: 18px;
    }

    tr {
      > :first-child {
        padding-left: 0;
      }
    }
  }

  tbody::before {
    line-height: 3em;
    content: '\200C';
    display: block;
  }

  tbody {
    td {
      color: $main-black;
      vertical-align: middle;
      border: none;

      padding-top: 0;
      padding-bottom: 0;

      p {
        font-size: 15px;
        font-weight: $font-light;
      }

      .font-medium {
        font-size: 1rem;
        font-weight: $font-normal;
      }

      button {
        width: 100%;
        font-size: 13px;
      }
    }

    tr:nth-child(even) td {
      background-color: #f8faff;
    }

    tr {
      > :first-child {
        padding-left: 0;
      }
    }
  }
}
