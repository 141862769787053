@import '../../../assets/scss/backend.scss';
@import '../../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../../assets/scss/variable';

.zestur-header {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  left: 0;
  height: $header-height;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  align-items: center;

  .header-logo {
    width: 100px;
  }

  .header-profile-desktop {
    width: 46px;
    height: 46px;
    border: 1px solid $gray-border;
    border-radius: 100%;
  }

  &__links {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 78px;

    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;

      &.withRedDot {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 3px;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $light-red;
        }
      }

      &.active {
        color: $dark-blue !important;
        font-weight: $font-semi-bold !important;
        border-right: 4px solid $dark-blue;
      }
    }

    @include tablet-breakpoint {
      gap: 24px;
    }
  }

  &__text-links {
    display: flex;
    gap: 48px;

    color: $graylight;
    font-size: 18px;
    font-weight: $font-medium;

    > div {
      cursor: pointer;
    }

    @include tablet-breakpoint {
      gap: 12px;
    }
  }

  &__icons {
    display: flex;
    gap: 26px;
    margin-right: 2rem;
  }
}

.zestur-header-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 90;
  width: 100%;
  left: 0;
  height: $header-height-mobile;
  padding: 6px 16px;
  box-sizing: border-box;
  background-color: #7aafbb;
  border-radius: 0 0 1.5rem 1.5rem;

  &::after {
    content: '';
    position: absolute;
    opacity: 0.5;
    left: 0;
    width: 100%;
    height: $header-height-mobile;
    border-radius: 0 0 1.5rem 1.5rem;
    background: $light-blue;
  }

  &::before {
    content: '';
    background-image: url('../../../assets/images/candidate-header-mobile.png');
    border-radius: 0 0 1.5rem 1.5rem;
    background-size: cover;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    z-index: 20;
  }

  .header-logo {
    width: 89px;
    height: 53px;

    &.company {
      width: 52px;
    }
  }

  .header-profile {
    width: 37px;
    height: 37px;
    border-radius: 100%;
    border: 2px solid #021c58;
  }

  &.company {
    &::after {
      background: $dark-blue;
    }

    &::before {
      background-image: url('../../../assets/images/company-header-mobile.png');
    }

    .header-profile {
      border-color: white;
    }
  }
}
