@import '../../assets/scss/variable';

.circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: $grey-neutral;
  overflow: hidden;
}

.circle .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  font-size: 14px;
  font-weight: $font-extra-bold;
  color: $dark-blue-alt;
}
