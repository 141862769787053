@import './breakpoint/breakpoints-mixins';
@import './backend/pages/authentication';
@import './mixins';
@import './variable';

$primary-color: #f5f5f5;
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $primary-color !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
  margin-bottom: 0px !important;
}
label {
  margin-bottom: 0px !important;
}
p,
span,
a {
  margin-bottom: 0px !important;
}
input,
::placeholder {
  color: $graylight;
}

.cursor-pointer {
  cursor: pointer;
}

.darkblue-link {
  color: $dark-blue;
  text-decoration: none;
}

.mobile-hidden {
  @include mobile-breakpoint {
    display: none !important;
  }
}

.tablet-hidden {
  @include tablet-breakpoint {
    display: none !important;
  }
}

.no-mobile-hidden {
  @include no-mobile-breakpoint {
    display: none !important;
  }
}

.no-tablet-hidden {
  @include no-tablet-breakpoint {
    display: none !important;
  }
}

.text-main-black {
  color: $main-black !important;
}

.text-dark-blue-alt {
  color: $dark-blue-alt !important;
}

.text-dark-blue {
  color: $dark-blue !important;
}

.modal-label {
  font-family: 'Oswald';
  font-size: 18px;
  font-weight: $font-medium;
  margin-top: 24px;

  @include mobile-breakpoint {
    margin-top: 1rem;
  }
}

.edit-modal {
  min-width: 80vw !important;

  .submitbtn {
    width: 288px !important;
    font-family: 'Open Sans' !important;
    margin-left: auto;

    @include mobile-breakpoint {
      width: 100% !important;
    }
  }
}

.no-mobile-mt-0 {
  @include no-mobile-breakpoint {
    margin-top: 0;
  }
}

.mobile-mt-2 {
  @include mobile-breakpoint {
    margin-top: 2rem !important;
  }
}

.tablet-mt-4 {
  @include tablet-breakpoint {
    margin-top: 1.5rem !important;
  }
}

.tablet-gap-1 {
  @include tablet-breakpoint {
    gap: 1rem !important;
  }
}

.font-14 {
  font-size: 0.9rem;
}

.dark-blue-link {
  color: $dark-blue;
  text-align: right;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: $font-semi-bold;
  line-height: normal;
  text-decoration-line: underline;
}

.rbt-menu.dropdown-menu.show {
  max-height: 280px !important;
}

.mw-0 {
  min-width: 0;
}

.text-small {
  font-size: 13px;
}

.text-default {
  font-size: 1rem;
}

.grey-filter {
  filter: invert(58%) sepia(0%) saturate(811%) hue-rotate(171deg)
    brightness(83%) contrast(96%);
}

.blue-filter {
  @include dark-blue-filter;
}

.white-filter {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(187deg)
    brightness(103%) contrast(103%);
}

.ligth-grey-filter {
  @include ligth-grey-filter;
}

.red-filter {
  filter: invert(78%) sepia(65%) saturate(5204%) hue-rotate(323deg)
    brightness(97%) contrast(111%);
}
