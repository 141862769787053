@import '../../../assets/scss/variable';

.tag-button {
  display: flex;
  align-items: center;
  padding: 4px 14px;
  gap: 10px;
  border-radius: 40px;
  border: none;
  background-color: $light-blue;

  span {
    color: white;
    font-size: 14px;
  }
}