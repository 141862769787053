@import '../../../assets/scss/breakpoint/breakpoints-mixins';

.search {
    margin-bottom: 2rem;

    &__confirm {
        min-width: 165px;
        padding: 12px 16px !important;
        border-radius: 12px !important;
    
        @include md-desktop-breakpoint {
            min-width: 90px;
        }
    }

    @include mobile-breakpoint {
        flex-direction: column;
        margin-top: 1rem;

        &__confirm {
            min-width: 100%;
        }
    }
}
