.g-counter {
  position: absolute;
  top: 0.5em;
  right: 0.5em;

  .circle {
    height: 50px;
    width: 50px;
  }
}
