@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';

.zestur-company-wrapper {
  background-color: $white-smoke;
  margin-left: 250px;
  margin-top: $header-height;
  padding: 1.2em;

  @include mobile-breakpoint {
    margin-left: 0;
    margin-top: $header-height-mobile;
  }
}
