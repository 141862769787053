@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';

$dropdown-border-radius: 12px;

.dropdown {
  position: absolute !important;
  max-height: 120px;
  overflow-y: auto;
  z-index: 9;
  margin-top: 6px;
  border-radius: $dropdown-border-radius;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &>:first-child {
    &:hover, &.selected {
      border-top-left-radius: $dropdown-border-radius;
      border-top-right-radius: $dropdown-border-radius;
    }
  }

  &>:last-child {
    &:hover, &.selected {
      border-bottom-left-radius: $dropdown-border-radius;
      border-bottom-right-radius: $dropdown-border-radius;
    }
  }

  &__option {
    font-size: 14px;
    padding: 12px;
    &:hover {
      background-color: $white-smoke;
    }
  }
  
  .selected {
    background-color: $white-smoke;
  }

  @include mobile-breakpoint {
    width: 110px;
  }
}