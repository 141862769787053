@import '../../breakpoint/breakpoints-mixins';
@import '../../breakpoint/breakpoints';
@import '../../variable';

$registration-gradient: linear-gradient(314deg, rgba(33, 56, 103, 0.60) 0.04%, rgba(33, 56, 103, 0.60) 6.45%, rgba(33, 57, 104, 0.60) 12.86%, rgba(35, 60, 106, 0.60) 19.27%, rgba(37, 64, 111, 0.60) 25.68%, rgba(41, 73, 118, 0.60) 32.09%, rgba(47, 85, 129, 0.60) 38.5%, rgba(58, 103, 143, 0.60) 44.91%, rgba(74, 126, 162, 0.60) 51.32%, rgba(93, 151, 180, 0.60) 58.52%, rgba(109, 169, 193, 0.60) 64.48%, rgba(122, 183, 202, 0.60) 70.56%, rgba(131, 192, 208, 0.60) 76.97%, rgba(136, 197, 212, 0.60) 83.38%, rgba(139, 200, 214, 0.60) 89.79%, rgba(140, 201, 215, 0.60) 96.2%, rgba(140, 201, 215, 0.60) 102.61%);
// Color Variables
$bluelight: #7aafbb;


// Common values
$wide-padding: 60px;
$extra-large-font: 200px;
// signabout Section
.max-height {
  height: 100vh;
}

.submitbtn {
  background: $dark-blue !important;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: $font-medium !important;
  line-height: normal !important;

  &.sans {
    font-family: 'Open Sans' !important;
  }
}

.input-label {
  font-family: "Oswald";
  font-size: 18px;
  font-style: normal;
  font-weight: $font-medium;
  line-height: normal;
  margin: 1rem 0 !important;

  @include mobile-breakpoint {
    font-size: 15px;
    margin-bottom: 12px!important;
  }
}

.form-control::placeholder {
  font-size: 18px;
  font-style: normal;
  font-weight: $font-normal;
  line-height: normal;
  color: $graylight;

  @include mobile-breakpoint {
    color: $main-black;
  }
}

input.form-control {
  padding: 10px;
}

input.auth-input {
  padding: 20px 32px 15px 32px !important;
  border: 1px solid $graylight !important;

  &::placeholder {
    color: $graylight !important;
  }

  @include mobile-breakpoint {
    padding: 16px 20px !important;
    border-color: $main-black !important;
  }
}

.form-control {
  border-radius: 8px !important;
  border: 1px solid $graylight !important;
  background: transparent !important;

  &.modal-input {
    border-radius: 8px!important;
    border: 1px solid $dark-blue!important;
    padding: 12px 36px;
  }
}

.autocomplete-modal-input {
  input {
    border-radius: 8px!important;
    border: 1px solid $dark-blue!important;
    padding: 12px 36px;
  }
}

.bg-image {
  // background-image: url("../../../images/job-portal/bg-img.jpeg");
  min-height: 100vh;
  padding: 0px 72px 0px 72px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include mobile-breakpoint {
    padding: 22px calc(13px + 16px);
  }
}

.auth-back {
  display: none;

  @include tablet-breakpoint {
    display: block;
    position: absolute;
    border: none;
    background-color: transparent;
    top: 13px;
    left: 0.5rem;
  }
}

.error-message {
  font-size: 16px;
  font-weight: 600;

  &.candidate {
    bottom: -1.4rem;

    @include mobile-breakpoint {
      right: 0;
    }
  }
}

.main {
  position: relative;
  color: $main-black;
  background: rgba(255, 255, 255, 0.9);
  padding: 32px;
  border-radius: 8px;

  h3 {
    font-family: 'Open sans';
    margin: 32px 0;
    font-size: 20px!important;
    font-weight: $font-semi-bold!important;

    
    @include mobile-breakpoint {
      font-size: 15px !important;
    }
  }

  @media (max-height: $smBreakpoint) {
    padding-top: 1rem;
    padding-bottom: 1rem;

    h3 {
      margin: 12px 0;
    }
  }

  @include mobile-breakpoint {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.register {
  font-size: 32px;
  font-style: normal;
  font-weight: $font-medium;
  line-height: normal;

  @include mobile-breakpoint {
    font-size: 1.5rem;
  }
}

.confrom {
  font-size: 20px;
  font-style: normal;
  font-weight: $font-normal;
  line-height: normal;
  margin-top: 18px;

  @include mobile-breakpoint {
    font-size: 15px;
  }
}
.confrom span,
.confrom a {
  color: #212529;
  font-size: 20px;
  font-style: normal;
  font-weight: $font-bold;
  line-height: normal;
  text-decoration-line: underline;

  @include mobile-breakpoint {
    font-size: 15px;
  }
}
.confrom.text-end {
  margin-top: 10px;
  margin-bottom: 48px !important;
}
.invalid-feedback {
  position: absolute;
  bottom: -20px;
}

.primary-button {
  background-color: $dark-blue !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: $font-medium !important;
  line-height: normal !important;
}

.empty-button {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid $dark-blue;
}

h3 {
  font-size: 18px !important;
  line-height: normal !important;
  position: relative;
  text-align: center;
  font-weight: normal !important;
}

h3:before {
  content: "";
  display: block;
  width: 90px;
  height: 2px;
  background: $dark-blue;
  left: 0;
  top: 50%;
  position: absolute;

  @include mobile-breakpoint {
    width: 3.5rem;
  }
}

h3:after {
  content: "";
  display: block;
  width: 90px;
  height: 2px;
  background: $dark-blue;
  right: 0;
  top: 50%;
  position: absolute;

  @include mobile-breakpoint {
    width: 3.5rem;
  }
}
button.submitbtn {
  margin: 25px 0px;
}
.social-icons {
  margin-top: 18px;
}

// LogIn page and SingIn page End

.selected-tags {
  margin-top: 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.suggestion-section {
  margin-top: 32px;
  h5 {
    font-size: 18px;
    margin-bottom: 1rem !important;
  }

  &__tag-container {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 20px;
    padding: 14px 17px;
    border-radius: 8px;
    border: 1px solid $dark-blue;
  }

  &__tag {
    color: white;
    border: none;
    font-size: 14px;
    border-radius: 40px;
    background: $graylight;
    padding: 4px 14px;
  }
}

.register-form {
  height: 100vh;

  .register-mobile-header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    padding: 36px 20px;
    width: 100%;
    min-height: 138px;

    &::before {
      content: "";
      background-image: url('../../../images/registration/register-mobile-header-cropped.jpg');
      background-position-x: 20%;
      background-position-y: 20%;
      background-size: cover;
      position: absolute;
      width: 100%;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.55;
    }

    &__background {
      background-color: $dark-blue;
      margin-bottom: 24px;
    }
  }

  .form {
    background-color: white;
  }

  .register-checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

    .checkbox-text {
      color: $main-black;
      text-decoration: underline;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: $font-normal;
    }

    input {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  .register-sidebar {
    height: 100vh;
  }

  &__robot {
    position: relative;
    height: 92px;
    margin-bottom: 62px;

    p {
      position: absolute;
      z-index: 2;
      text-align: center;
      padding: 22px 84px;
      border-radius: 0px 8px 8px 8px;
      color: white;
      background-color: $dark-blue;
    }

    &>:first-child {
      position: absolute;
      left: -11px;
      top: -1px;
      z-index: 2;
    }

    &>:last-child {
      position: absolute;
      z-index: 1;
      width: 200px;
      height: 200px;
      left: -163px;
      top: -88px;
    }

    @include tablet-breakpoint {
      p {
        padding: 9px;
        font-size: 14px;
        left: 75px;
      }

      &>:first-child {
        left: 65px;
      }
  
      &>:last-child {
        width: 80px;
        height: 80px;
        left: 0;
        top: -37px;
      }
    }

    @include mobile-breakpoint {
      margin-bottom: 24px;
    }
  }

  &__header {
    margin: 62px 0;
  }

  .main-ProgressBar {
    height: calc(100vh - 70vh);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &__add {
      p {
        text-align: center;
        font-family: 'Oswald';
        font-weight: $font-medium;
        font-size: 12px;
        color: $dark-blue;
      }

      @include mobile-breakpoint {
        img {
          width: 32px;
          height: 32px;
        }

        margin-right: 2rem;
        align-items: center;
      }
    }
  }
  .form-fields {
    height: calc(70vh - 20vh);
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: nowrap !important;

    &.candidate {
      gap: 1.5rem;
      justify-content: initial;
      @include mobile-breakpoint {
        gap: 0;
        height: 100%!important;
        align-items: center;

        .row {
          padding: 0;
        }
      }
    }
  }

  .buttons {
    height: 20vh;
  }

  h2 {
    color: $dark-blue;
    font-size: 35px;
    font-weight: $font-medium;

    @include mobile-breakpoint {
      font-size: 24px;
    }
  }
  .register-sidebar {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: center;

    img {
      margin-top: 32px;
    }

    &.candidate {
      min-height: 100vh;
      background-image: $registration-gradient, url("../../../images/job-portal/bg-img.jpeg")!important;
      background-position-x: 65%;
    }

    &.candidate-robot {
      min-height: 100vh;
      background-image: $registration-gradient, url("../../../images/registration/robot.png")!important;
      background-position-x: 57%;

      img {
        object-fit: contain;
      }
    }

    &.company {
      background-image: url("../../../images/job-portal/bg-img.jpeg");
      background-image: $registration-gradient, url("../../../images/job-portal/bg-img.jpeg")!important;
    }
  }
  .form {
    padding: 0px 40px;
    .progress {
      margin-top: 20px !important;
      height: 16px !important;
    }

    .label {
      font-family: "Oswald";
      font-size: 18px;
      font-style: normal;
      font-weight: $font-medium;
      line-height: normal;
    }

    @include mobile-breakpoint {
      padding: calc(13px + 16px);

      .buttons {
        margin-top: 12px;
        flex-direction: column-reverse;
        gap: 12px;

        button {
          margin: 0!important;
          width: 100%;
        }
      }
    }
  }
  .buttons {
    .btn1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 286px;
      height: 60px;
      background: none;
      border-radius: 8px;
      border: 1px solid $dark-blue;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      color: $dark-blue;
    }
    .btn2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 286px;
      height: 60px;
      background: $dark-blue;
      border-radius: 8px;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      line-height: normal;
      color: $white;
    }
  }
  .progress-bar {
    border-radius: 6px !important;
    background: linear-gradient(
      60deg,
      $blue 7.63%,
      $bluelight 92.37%
    ) !important;
  }
  .size {
    resize: none;
  }
  .file-upload {
    color: $main-black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    padding: 32px;
    align-items: center;
    border-radius: 20px;
    background-color: $white-smoke;

    h5 {
      font-size: 18px;
      font-weight: $font-medium;
    }

    p {
      font-size: 18px;
      font-weight: $font-normal;

      span {
        color: $light-blue;
      }
    }

    h2 {
      color: $main-black;
    }

    .FileUplodeImg {
      width: 152px;
      height: 152px;
      cursor: pointer;
      border-radius: 50%;

      margin-top: 18px;
      margin-bottom: 32px;
    }

    .fileuploaddata {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    @include mobile-breakpoint {
      p {
        font-size: 14px;
        text-align: center;
      }

      .FileUplodeImg {
        width: 114px;
        height: 114px;
      }
    }
  }

  .form-check-label {
    font-family: "Open Sans";
    font-size: 14px;
    color: $graylight;
    font-weight: $font-normal;
    line-height: 24px;
    letter-spacing: 0.28px;
    text-decoration-line: underline;
    margin-left: 10px;
  }
  .form-check-input {
    width: 24px;
    height: 24px;
    border-radius: 2px !important;
  }
  .form-check {
    padding-left: 25px;
  }
  .form-check-input:checked {
    background-color: $dark-blue;
    border-color: $dark-blue;
  }
}

.textarea {
  &__counter {
    color: $graylight;
    font-size: 14px;
    position: absolute;
    right: 24px;
    bottom: 6px;
  }
}

// modalBox styling
.modalBox {
  width: 744px;
  height: 596px;
  border-radius: 20px;
  background: white;
  padding: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  &.candidate {
    justify-content: center;
    gap: 12px;

    >:first-child {
      display: flex;
      gap: 12px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 18px;
        margin-top: 14px;
      }
    }

    button {
      margin-top: 24px;
    }

    @include mobile-breakpoint {
      h6 {
        font-size: 24px;
      }

      >:first-child {
        p {
          font-size: 15px;
        }
      }
    }
  }

  h6 {
    font-size: 40px;
    font-style: normal;
    font-weight: $font-medium;
    line-height: normal;
  }
  p {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: $font-normal;
    line-height: 24px;
    letter-spacing: 0.28px;
  }
  button {
    display: flex;
    width: 287px;
    height: 50px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    background: $dark-blue;
  }
  .list-group-item {
    border: none;
  }
  Img {
    width: 66px;
    height: 66px;
  }

  .candidate-image {
    position: relative;

    &>:first-child {
      width: 140px;
      height: 140px;
    }

    &>:last-child {
      position: absolute;
      bottom: -4px;
      right: -20px;
    }
    
  }

  @include tablet-breakpoint {
    width: 90vw !important;
  }
}

.mobile-column {
  @include mobile-breakpoint {
    flex-direction: column;

    &.first-row {
      gap: 1rem;
    }

    >div {
      width: 100%;
    }
  }
}
