@import '../../../../assets/scss/variable';
@import '../../../../assets/scss/breakpoint/breakpoints-mixins';

.upload-heading-main {
  font-size: 26px !important;
  font-weight: $font-normal !important;
  margin: 0;
  line-height: 34px;

  @include mobile-breakpoint {
    font-size: 24px !important;
    font-weight: $font-medium !important;
    line-height: 30px;
  }
}
