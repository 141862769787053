@import '../../assets/scss/variable';
@import '../../assets/scss/breakpoint/breakpoints-mixins';

.content {
  color: $main-black;
  background-color: white;
  text-align: left;
  height: 100vh !important;

  &__logo {
    position: relative;
    margin-top: 22px;
    margin-left: 6px;
    width: 190px;
    height: 120px;
    z-index: 1000;
  }

  &__subtitle {
    margin-top: 1rem;
    margin-bottom: 0;
    color: $graylight;
    font-size: 18px!important;
    margin-left: 15px;
  }

  // About Section
  .signAbout {
    padding: 3px 40px;
    padding-left: 120px;
    background-color: white;

    h1 {
      font-size: 300px;
      font-weight: $font-semi-bold!important;
      line-height: 1;
      color: #f6f6f6;
    }

    h2 {
      position: absolute;
      font-size: 80px;
      top: 3.5rem;
      left: 15px;
      font-weight: $font-medium;
      line-height: 98px;
    }

    .services {
      background: #f6f6f6;
      border-radius: 5px;
      width: 22%;
      height: 95px;
      img {
        width: 35px;
        height: 35px;
      }

      h4 {
        font-size: 18px;
        font-weight: $font-medium;
      }
    }

    .feature {
      margin-top: 1.5rem;
    }

    .about-services {
      width: 67%;
      p {
        font-size: 18px;
      }
    }


    @media (max-height: $smBreakpoint) {
      &__subtitle {
        font-size: 1rem;
        margin-top: 1rem;
      }

      h1 {
        font-size: 200px;
        line-height: 0.8;
      }

      h2 {
        font-size: 60px;
        top: 1.5rem;
        line-height: 60px;
      }

      .feature {
        margin-top: 1rem;
      }
    }
  }

  @include tablet-breakpoint {
    &__subtitle {
      margin-left: 0;
    }

    .signAbout {
      display: flex;
      flex-direction: column;
      padding-left: 4rem;
      padding-right: 4rem;

      h2 {
        position: initial;
      }
    }

    &__buttons {
      margin-top: 48px;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  @include mobile-breakpoint {
    height: 100% !important;
    min-height: 100vh;

    &__logo {
      margin-top: 12px;
      margin-left: 13px;
      width: 96px;
      height: 61px;
    }

    &__subtitle {
      font-size: 15px !important;
      color: $main-black;
      margin-top: 4px;
      margin-bottom: 12px !important;
      font-weight: $font-normal;
    }

    .signAbout {
      padding: 12px calc(13px + 16px);

      h2 {
        font-size: 24px;
        font-weight: $font-medium;
        line-height: 30px;
      }

      .services {
        img {
          width: 41px;
          height: 41px;
        }

        h4 {
          font-size: 15px !important;
        }
      }
    }

    .feature {
      gap: 14px !important;
    }

    .about-services {
      p {
        font-size: 15px !important;
      }
    }
  }
}