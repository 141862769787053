.language {
  display: grid;
  grid-template-columns: repeat(2, 2fr);

  margin-bottom: 12px;
  gap: 32px;

  h5 {
    margin-bottom: 1rem !important;
  }
}
