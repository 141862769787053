@import '../../../assets/scss/breakpoint/breakpoints-mixins';

.vacancies-table-filter {
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr);

  @include mobile-breakpoint {
    grid-template-columns: repeat(2, 1fr);
  }
}
