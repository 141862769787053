@import '../../../../assets/scss/breakpoint/breakpoints-mixins';

.error-message {
  &.candidate {
    position: absolute;
    @include mobile-breakpoint {
      &.long {
        position: static;
      }
    }
  }
}
