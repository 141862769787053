@import './variable';

@mixin grey-icon-button {
  width: 3rem;
  height: 3rem;
  padding: 12px;
  border: none;
  border-radius: 0.5rem;
  background-color: rgba(233, 233, 233, 0.6);

  &:hover {
    background-color: rgba(233, 233, 233, 0.8);
  }

  >:first-child {
    height: 1.5rem;
    width: 1.5rem;
  }

  &.activated {
    background-color: $light-blue !important;

    &:hover {
      background-color: #0c6daa !important;
    }
  }
}

@mixin dark-blue-filter {
  filter: invert(13%) sepia(52%) saturate(2343%) hue-rotate(206deg) brightness(93%) contrast(84%);
}

@mixin ligth-grey-filter {
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(151deg) brightness(99%) contrast(94%);
}