@import '../../assets/scss/variable';

.zestur-card {
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1em;
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5em;
        h4 {
            font-weight: $font-semi-bold;
        }
    }
    &__body {
        margin: 0.5em 0;
    }
    &-cover {
        width: 100%;
        position: relative;
        margin-bottom: 3.4em;
        &-holder {      
            width: 100%;
            overflow: hidden;
            height: 200px;
            img {
                width: 100%;
                border-radius: 10px;
            }
        }
        .profile-picture {
            width: 114px;
            height: 114px;
            border-radius: 50%;
            position: absolute;
            bottom: -3em;
            left: 50%;
            transform: translateX(-50%);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 5px solid $gray-border;
            }
        }
    }
    .profile-section {
        padding: 0 1rem 1rem;
        word-wrap: break-word;
        .name {
            margin: 0em 0;
            font-weight: $font-semi-bold;
            text-align: center;
        }
        .qr-code {
            font-size: 14px;
            text-align: center;
        }
        .sub-title {
            margin-bottom: 1em;
            text-align: center;
        }
        ul {
            list-style-type: none;
            margin: 1em 0;
            padding: 0;
            padding-left: 0.8em;
            li {
                margin: 1em 0;
                font-size: 14px;
                span {
                    padding-left: 1em;
                }
            }
        }
    }
}

.card-fixed {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 0 0 100%;
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $dark-blue;
    line-height: 20px;

    &__header {
        color: $main-black;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: $font-semi-bold;
        text-overflow: ellipsis;
        margin-bottom: 5px;
    }
    &__details {
        color: $dark-grey-alt;
        font-family: "Open Sans";
        font-size: 13px;
        font-weight: $font-light;
    }

    &__description {
        margin-top: 6px;
        color: $dark-grey-alt;
        font-size: 15px;
        font-style: normal;
        font-weight: $font-normal;
        flex-grow: 1;
    }

    &__salary {
        color: $main-black;
        font-family: "Open Sans";
        font-size: 15px;
        font-weight: $font-normal;
    }

    button {
        border-radius: 9px;
    }
}

.blog-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    &-image {
        width: 100%;
        height: 200px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }
    &-title {
        padding: 3em 1em;
        text-align: center;
        font-size: 12px;
        font-weight: $font-bold;
    }
}
