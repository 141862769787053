@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import './assets/fonts/Oswald/index.scss';
@import './assets/scss/variable';

html,
body {
  font-family: 'Open Sans', sans-serif !important;
}
.App {
  overflow-x: hidden;
}
