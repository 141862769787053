@import '../../scss/variable';

@font-face {
  font-family: 'Oswald';
  font-weight: $font-extra-light;
  src: local('OswaldExtraLight'),
    url('./Oswald-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: $font-light;
  src: local('OswaldLight'), url('./Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: $font-normal;
  src: local('OswaldRegular'), url('./Oswald-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: $font-medium;
  src: local('OswaldMedium'), url('./Oswald-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: $font-semi-bold;
  src: local('OswaldSemiBold'), url('./Oswald-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: $font-bold;
  src: local('OswaldBold'), url('./Oswald-Bold.ttf') format('truetype');
}
