@import '../../../../assets/scss/breakpoint/breakpoints-mixins';

.offer-details-row {
  margin: 0 48px !important;

  @include tablet-breakpoint {
    margin: 0 1rem !important;
  }

  @include mobile-breakpoint {
    margin: 0 !important;
  }
}
