@import '../../../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../../../assets/scss/variable';

.filter-row {
  gap: 2.5rem;

  .tag-container {
    gap: 1.5rem;
    margin-right: 1.5rem;
  }

  .clean-button {
    color: $dark-blue-alt;
    font-size: 14px;
    font-weight: $font-semi-bold;

    cursor: pointer;
    border: none;
  }

  .filter-selected {
    display: flex;
    align-items: center;
  }

  @include mobile-breakpoint {
    gap: 0.5rem;
    justify-content: end;
  }
}

