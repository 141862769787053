@import '../../../assets/scss/mixins';
@import '../../../assets/scss/variable';

.zestur-sidebar {
    position: fixed;
    top: 0;
    z-index: 11;
    width: 250px;
    left: 0;
    height: 100%;
    box-sizing: border-box;
    background-color: white;
    border-right: 1px solid $white-smoke;

    &__header {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 3em 0;
    }
    &__links {
        .logout-btn {
            border: none !important;
            outline: none;
        }
        &-item {
            display: flex;
            align-items: center;
            padding: 1.5em 1.5em;
            font-weight: $font-medium;
            margin-bottom: 1px;
            color: $graylight;
            text-decoration: none;
            border-right: 4px solid transparent;
            background-color: transparent;
            .icon-container {
                width: 30px;
                height: 30px;
            }
            .item-text {
                margin-left: 1em;
            }
            &.active {
                color: $dark-blue !important;
                font-weight: $font-semi-bold !important;
                border-right: 4px solid $dark-blue;

                img {
                    @include dark-blue-filter;
                }
            }
        }
    }
}
