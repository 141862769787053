@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  span {
    font-size: 16px;
    color: $dark-blue;
    padding-top: 3px;
  }

  &__button {
    &.deactivated {
      cursor: auto !important;
      @include ligth-grey-filter;
    }
  }

  .page-item {
    margin: 0 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    &:hover:not(.deactivated) {
      background-color: rgba(12, 133, 208, 0.15);
    }

    &.active {
      background-color: $white-smoke;
      color: $light-blue;
    }
  }
  select {
    border: 1px solid $grey-neutral;
    padding: 0.8em 1em;
    font-size: 12px;
    border-radius: 10px;
  }
}
