@import '../../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../../assets/scss/variable';

.candidateList {
  display: flex;
  flex-direction: column;
  gap: 26px;
  font-weight: $font-light;
  width: 100%;
}

.candidateListItem {
  display: grid;
  grid-template-columns: 2.5fr repeat(2, 1fr) auto;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
}

.candidateListMobile {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.avatar {
  width: 61px;
  height: 61px;
  border-radius: 100%;
}
