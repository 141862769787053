@import '../../assets/scss/variable';

.app-label {
    font-size: 16px;
    font-weight: bold;
}

.form-control {
    border-color: $dark-blue !important;
}

.form-control-gray {
    border-color: $graylight !important;
}
.form-select {
    border-color: $dark-blue !important;
    padding: 10px 1em !important;
}

.zestur-check {
    display: inline-block;
    input[type="checkbox"] {
        visibility: hidden;
    }
    .checkbox {
        position: absolute;
        left: -9999px;

        & + label {
            position: relative;
            display: inline-block;
            padding: 8px 30px;
            cursor: pointer;

            &:before,
            &:after {
                position: absolute;
                top: 0;
                content: "";
                border-radius: 1px;
            }

            &:before {
                border-radius: 2px;
                left: 0;
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                background-color: #fff;
            }

            &:after {
                /* hidden */
                width: 0;
                height: 0;
            }
        }

        &:checked {
            & + label {
                &:before {
                    border-color: $dark-blue;
                }

                &:after {
                    width: 6px;
                    height: 13px;
                    top: 3px;
                    left: 7px;

                    color: $dark-blue;
                    border-top: 2px solid transparent;
                    border-left: 2px solid transparent;
                    border-right: 2px solid $dark-blue;
                    border-bottom: 2px solid $dark-blue;

                    -webkit-transform: rotate(37deg);
                    -ms-transform: rotate(37deg);
                    -o-transform: rotate(37deg);
                    transform: rotate(37deg);
                }
            }
        }

        &:disabled {
            & + label {
                color: #ccc;

                &:before {
                    border-color: #ccc;
                    background-color: #f2f2f2;
                    box-shadow: none;
                }

                &:after {
                    /* hidden */
                    width: 0;
                    height: 0;
                }
            }
        }
    }
}

.search-box {
    background-color: white;
    border-radius: 12px;
    position: relative !important;
    .icon {
        position: absolute;
        left: 1em;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
    }

    input {
        font-family: "Open Sans", sans-serif !important;
        font-size: 18px;
        border: none !important;
        outline: none !important;
        padding: 12px !important;
        padding-left: 3em !important;

        &::placeholder {
            color: $grey !important;
            font-family: "Open Sans", sans-serif!important;
            font-size: 18px;
        }
    }
}
