@import "_breakpoints";

@mixin mobile-breakpoint {
    @media (max-width: $xsBreakpoint) {
        @content;
    }
}

@mixin no-mobile-breakpoint {
    @media (min-width: $xsBreakpoint) {
        @content;
    }
}

@mixin tablet-breakpoint {
    @media (max-width: $smBreakpoint) {
        @content;
    }
}

@mixin no-tablet-breakpoint {
    @media (min-width: $smBreakpoint) {
        @content;
    }
}

@mixin md-desktop-breakpoint {
    @media (max-width: $mdBreakpoint) {
        @content;
    }
}

@mixin desktop-breakpoint {
    @media (min-width: $smBreakpoint) {
        @content;
    }
}

@mixin large-breakpoint {
    @media (min-width: $lgBreakpoint) {
        @content;
    }
}
