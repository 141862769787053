@import '../../../assets/scss/variable';

.qr-logo {
  width: 42px;
  height: 42px;
  top: 50%;
  left: 50%;
  box-sizing: content-box;
  border: 5px solid $dark-blue;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
