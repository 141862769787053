@import '../../../assets/scss/variable';

.warning {
  position: fixed;
  text-align: center;
  padding: 2px;
  width: 100%;
  background-color: $light-red;
  top: 0;
  z-index: 1000;
}
