@import '../../assets/scss/variable';
@import '../../assets/scss/breakpoint/breakpoints-mixins';

.plans-container {
    padding: 1.5em 1em;
    border: 1px solid $dark-blue;
    border-radius: 10px;
    transition: .3s ease-in-out;
    &.bg {
        background-color: $light-blue;
        color: #fff;
    }
    &:hover {
        transform: scale(1.1);
    }
    &-header {
        border-bottom: 1px solid $graylight;
        box-sizing: border-box;
        margin-bottom: 1em;
        margin-top: 1em;
        padding-bottom: 1em;
        text-align: center;
        &__title {
            font-size: 20px;
            font-weight: $font-semi-bold;
            box-sizing: border-box;
        }
        &__rate {
            font-weight: bold;
            font-size: 40px;
            box-sizing: border-box;

            @media only screen and (max-width: 1350px) {
                font-size: 28px;
            }
        }
        &__subrate {
            font-weight: normal;
            font-size: 14px;
            box-sizing: border-box;
            margin-left:0.5em;
            display:none;

            @include large-breakpoint {
                display:initial;
            }
        }
    }
    &-content {
        font-size: 14px;
        height: 100px;
    }

    .mt-5 {
        @media only screen and (max-width: 1650px) {
            margin-top: 6rem !important;
        }
        @media only screen and (max-width: 1300px) {
            margin-top: 12rem !important;
        }
    }
}
