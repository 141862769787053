@import '../../assets/scss/breakpoint/breakpoints-mixins';

.language-selector {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 1.5rem;

  &__box {
    cursor: pointer;
    left: 0;
    width: 2rem;
    height: 1.5rem;
    position: absolute;
    opacity: 0;
  }

  @include mobile-breakpoint {
    width: 42px;
    height: 2rem;
    margin-left: 1.5rem;
    &__box {
      width: 42px;
      height: 2rem;
    }
  }
}
