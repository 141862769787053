@import '../../assets/scss/variable';

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.breadcrumbLink {
  white-space: nowrap;
  text-decoration: none;
  color: black;

  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: $font-semi-bold;
  line-height: normal;
}
