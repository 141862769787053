@import '../../../assets/scss/breakpoint/breakpoints-mixins';

@include mobile-breakpoint {
  .menu {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 100;
    top: 0;
    background-color: white;

    &__close {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1.5rem;

      &-icon {
        cursor: pointer;
        margin-right: 1rem;
        width: 42px;
        height: 42px;
      }
    }
  }
}
