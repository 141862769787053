@import '../../assets/scss/breakpoint/breakpoints-mixins';
@import '../../assets/scss/variable';

.content {
  &.home {
    gap: 48px;
  }

  &__home-logo {
    margin-top: 22px;
    max-height: 142px;

    >:first-child {
      margin-top: 22px;
    }

    .home-lang-select {
      margin-right: 2rem;
      margin-top: 1rem;
    }
  }

  &__home-header {
    text-align: center;
    font-size: 48px;
    font-weight: $font-medium;
    line-height: 96px;
  }

  @include mobile-breakpoint {
    &__home-header {
      text-align: start;
      font-size: 36px;
      line-height: initial;
    }

    &__home-logo {
      >:first-child {
        width: 96px;
        margin-top: 6px;
      }

      .home-lang-select {
        margin-right: 1rem;
        margin-top: 0.5rem;
      }
    }
  }

  @include tablet-breakpoint {
    &.home {
      min-height: 100vh;
      height: auto !important;
      gap: 12px;
    }
  }
}
